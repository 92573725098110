/**
 * @generated SignedSource<<b63cb0a2f3d95139756d184f3dabb17a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Metadata_object$data = {
  readonly id: string;
  readonly metadata: ReadonlyArray<{
    readonly name: string;
    readonly value: string | null | undefined;
  }>;
  readonly " $fragmentType": "Metadata_object";
};
export type Metadata_object$key = {
  readonly " $data"?: Metadata_object$data;
  readonly " $fragmentSpreads": FragmentRefs<"Metadata_object">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Metadata_object",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Metadata",
      "kind": "LinkedField",
      "name": "metadata",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Object",
  "abstractKey": "__isObject"
};

(node as any).hash = "6bb7758d194b3cf0fef06b516c4d6f2e";

export default node;
