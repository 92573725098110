/**
 * @generated SignedSource<<413c0de99d4dde07271ed97c9dbce797>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TransactionStatus = "INCOMPLETE" | "REVERSED" | "SUCCESSFUL" | "%future added value";
export type TransactionType = "ORDER" | "TRANSIT_JOURNEY" | "%future added value";
export type TransactionQuery$variables = {
  organisationId: string;
  transactionId: string;
};
export type TransactionQuery$data = {
  readonly organisation: {
    readonly transaction: {
      readonly createdDate: any;
      readonly id: string;
      readonly modifiedDate: any;
      readonly status: TransactionStatus;
      readonly transactionDate: any;
      readonly transactionType: TransactionType;
    } | null | undefined;
  } | null | undefined;
};
export type TransactionQuery = {
  response: TransactionQuery$data;
  variables: TransactionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transactionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "transactionId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modifiedDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transaction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "transaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f07a175367846df01d9780c2a5a0da02",
    "id": null,
    "metadata": {},
    "name": "TransactionQuery",
    "operationKind": "query",
    "text": "query TransactionQuery(\n  $organisationId: ID!\n  $transactionId: ID!\n) {\n  organisation(id: $organisationId) {\n    transaction(id: $transactionId) {\n      __typename\n      id\n      transactionType\n      status\n      createdDate\n      transactionDate\n      modifiedDate\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1dd5d9233bc23fd204797435108b7249";

export default node;
