import React, { useState } from 'react';
import Icon from '@mdi/react';

import { Avatar } from './components/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from './components/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from './components/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from './components/sidebar'
import { SidebarLayout } from './components/sidebar-layout'
import { mdiChevronDown, mdiChevronUp, mdiCog, mdiFormatListCheckbox, mdiLogout, mdiMagnify, mdiSwapHorizontal, mdiViewDashboard } from '@mdi/js';
import Search from './Search';
import { srcOrganisationQuery$data } from './__generated__/srcOrganisationQuery.graphql';
import { NotFound } from './components/notfound';

function Organisation({children, organisation, me, ...props}: { children: React.ReactNode, props: any, organisation: srcOrganisationQuery$data["organisation"], me:srcOrganisationQuery$data["me"]}) {
  let [isSearchOpen, setIsSearchOpen] = useState(false);
  if(!organisation || !me) {
    return <NotFound />;
  }
  var orgName=organisation?.name;
          document.title=orgName===null || orgName===undefined ? "Dashboard | Beep" : orgName+" | Beep";
          return (
<SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <NavbarItem href="/search" aria-label="Search">
            <Icon path={mdiMagnify} size={1} />
            </NavbarItem>
            <NavbarItem href="/inbox" aria-label="Inbox">
            <Icon path={mdiFormatListCheckbox} size={1} />
            </NavbarItem>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src="/profile-photo.jpg" square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/my-profile">
                <Icon path={mdiFormatListCheckbox} size={1} />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href={'/'+organisation?.id+'/settings'}>
                <Icon path={mdiCog} size={1} />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                <Icon path={mdiCog} size={1} />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
          <SidebarHeader>
            <Dropdown>
              <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar initials={organisation?.name?.match(/\b(\w)/g)?.join('')} />
                <SidebarLabel>{organisation?.name}</SidebarLabel>
                <Icon path={mdiChevronDown} size="20px" />
              </DropdownButton>
              <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                <DropdownItem href={'/'+organisation?.id+'/settings'}>
                <Icon path={mdiCog} size="16px" />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <SidebarSection className="max-lg:hidden">
              <SidebarItem onClick={() => {
                setIsSearchOpen(true);
              }}>
              <Icon path={mdiMagnify} size="20px" />
                <SidebarLabel>Search</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <SidebarItem href={'/'+organisation?.id}>
              <Icon path={mdiViewDashboard} size="20px" />
                <SidebarLabel>Dashboard</SidebarLabel>
              </SidebarItem>
              <SidebarItem href={'/'+organisation?.id+'/entities'}>
              <Icon path={mdiFormatListCheckbox} size="20px" />
                <SidebarLabel>Cardholders</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar initials={(me.firstName+" "+me.surname).match(/\b(\w)/g)?.join('')} className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{me.firstName}</span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400"></span>
                  </span>
                </span>
                <Icon path={mdiChevronUp} size={1} />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="https://dashboard.beep.dev/">
                <Icon path={mdiSwapHorizontal} size="16px" />
                  <DropdownLabel>Switch Organisation</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="https://dashboard.beep.dev/settings">
                <Icon path={mdiCog} size="16px" />
                  <DropdownLabel>Account Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="https://dashboard.beep.dev/logout">
                <Icon path={mdiLogout} size="16px" />
                  <DropdownLabel>Logout</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      <Search open={isSearchOpen} setOpen={setIsSearchOpen} />
      {children}
    </SidebarLayout>);
  
}

export default Organisation;
