export function NotFound() {
  return (
    <>
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl">Page not found</h1>
          <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-300">Sorry, we couldn't find the page you're looking for.</p>
        </div>
      </main>
    </>
  )
  }