
//import type {TransactionsPaginationQuery_organisation$key} from "./__generated__/TransactionsPaginationQuery.graphql";

import { Table, TableBody, TableCell, TableHead, TableRow } from './components/table';

function Transactions(props : any) {
    //const classes = useStyles();
    //let { organisationId }:{organisationId:string} = useParams();
    /*return(
        <div></div>
    );*/
    /*const {data/*, loadNext} = usePaginationFragment<TransactionsPaginationQuery, any>(
        graphql`
          fragment Transactions_organisation on Organisation
          @refetchable(queryName: "TransactionsPaginationQuery")
          @argumentDefinitions(
            count: {type: "Int", defaultValue: 10},
            cursor: {type: "Int", defaultValue: -1},
          ) {
            transactions(first: $count, after: $cursor)
            @connection(key: "Transactions_organisation_transactions") {
              edges {
                node {
                  id
                  transactionType
                  status
                  transactionDate
                }
              }
            }
          }
        `,
        props.organisation,
      );    */
    return (
      <><h2>Transactions</h2>
      <Table>
      <TableHead>
          <TableRow>
          <TableCell>Transaction</TableCell>
          <TableCell>Timestamp</TableCell>
          </TableRow>
      </TableHead>
      <TableBody>
      {/*(data.transactions?.edges ?? []).map((edge: { node: { id: React.Key | null | undefined; transactionType: string | null | undefined; status: string | null | undefined; transactionDate: number | null; }; }) => {
          return (
              <Suspense fallback={<div></div>}>
                  <TableRow key={edge.node.id}>
                      <TableCell scope="row">
                      <Link href={`/${props.organisation}/transactions/${edge.node.id}`}>{edge.node.transactionType} {edge.node.status}</Link>
                      </TableCell>
                      <TableCell>{edge.node.transactionDate===null ? '' : formatRelative(new Date(edge.node.transactionDate), new Date(),{locale:enGB})}</TableCell>
                  </TableRow>
              </Suspense>
          );
      })*/}
      </TableBody>
      </Table></>);
  }

export default Transactions;