import React, { useReducer, useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment, useMutation } from 'react-relay/hooks';
import { Metadata_object$key } from './__generated__/Metadata_object.graphql';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { Metadata_Edit_Mutation } from './__generated__/Metadata_Edit_Mutation.graphql';
import { Button } from './button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from './description-list';
import { GridContainer, GridItem } from './grid';
import { Input } from './input';
import Card from './card';

export function Metadata(props:{object:Metadata_object$key, organisationId:string}) {
  const [editing, setEditing] = useState(false);
  const [editableData, setEditableData] = useReducer((state: any[], action: { type: any; index: any | null;field: any | null;value: any | null; }) => {
    switch (action.type) {
      case 'add':
        return [
            ...state,
            {
              name: '',
              value:'',
            }
          ];
      case 'edit':
          var ts=[...state];
          ts[action.index][action.field]=action.value;
          return ts;
      case 'set':
          return action.value;
      case 'remove':
        // keep every item except the one we want to remove
        return state.filter((_: any, index: any) => index !== action.index);
      default:
        return state;
    }
  }, []);

  const [edit]=useMutation<Metadata_Edit_Mutation>(graphql`
  mutation Metadata_Edit_Mutation(
    $organisationId:ID!,
    $objectId:ID!,
    $metadata:[MetadataInput]
  ) {
    editMetadata(organisation: $organisationId, object: $objectId, metadata: $metadata) {
      ...Metadata_object
      ...ActivityLog_object
    }
  }
`);
    const data = useFragment(
        graphql`
          fragment Metadata_object on Object {
            id
            metadata {
            name
            value
          }
          }
        `,
        props.object,
      );
  if(data?.metadata!==null && data?.metadata!==undefined) {
    return (<Card title="Metadata" buttons={<div hidden={editing}><Button disabled={editing} onClick={() => {setEditableData({type:'set',value:data.metadata.map(a=>{return {...a}}), field:null, index:null});setEditing(true);}}>Edit Metadata</Button></div>}>
        <div hidden={editing}>
        <DescriptionList>
        {(data.metadata ?? []).map((meta, index:number) => {
                return (<>
                  <DescriptionTerm>{meta.name}</DescriptionTerm>
                  <DescriptionDetails>{meta.value}</DescriptionDetails>
                  </>);
            })}
    </DescriptionList></div>
    <div hidden={!editing}>
    <GridContainer className="mb-3">
        {(editableData ?? []).map((meta: { name: string; value: string; }, index:number) => {
                return (
                    <>
                            <GridItem span={2}><Input name="Name" placeholder="Name" value={meta.name} onChange={(event) => {setEditableData({type:'edit',index:index,field:'name',value:event.target.value});}} /></GridItem>
                            <GridItem span={8}><Input name="Value" placeholder="Value" value={meta.value} onChange={(event) => {setEditableData({type:'edit',index:index,field:'value',value:event.target.value});}} /></GridItem>
                            <GridItem span={2}><Button onClick={() => {setEditableData({type:'remove',index:index,field:null,value:null});}} aria-label="delete"><Icon path={mdiDelete} size={1} /></Button></GridItem>
                    </>
                );
            })}
                    <GridItem span={5}>
                        <Button onClick={() => {setEditableData({type:'add',index:null,field:null,value:null});}}>Add another item</Button>
                    </GridItem>
                    <GridItem className="text-right" span={7}>
                        <Button className="mr-2" onClick={() => setEditing(false)}>Cancel</Button>
                        <Button onClick={() => {
                            var md:any={};
                            editableData.forEach((element: { name: string; value: any; }) => {
                                md[element.name]=element.value;
                            });
                            data.metadata.forEach((element) => {
                                if(md[element.name]===undefined) {
                                    md[element.name]=null;
                                }else{
                                    if(md[element.name]===element.value) {
                                        delete md[element.name];
                                    }
                                }
                            });
                            var op=Object.keys(md).map(e => {return {name:e,value:md[e]}});
                            edit({
                                variables:{organisationId:props.organisationId,objectId:data.id, metadata:op},
                                onCompleted(data) {
                                  setEditing(false);
                                },
                              });
                        }}>Save</Button>
                    </GridItem>
                </GridContainer>
    </div></Card>);
  }else{
    return (<div></div>);
  }   
}