import React from 'react';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { graphql } from 'babel-plugin-relay/macro';

import { createRoot } from 'react-dom/client';

import { BrowserProtocol, queryMiddleware } from 'farce';
import { createFarceRouter, createRender, makeRouteConfig, Route, Resolver as FoundResolver } from 'found';
import { Resolver } from 'found-relay';

import environment from './relayenv';
import Organisation from './Organisation';
import OrganisationSettings from './OrganisationSettings';
import Entities from './Entities';
import Transactions from './Transactions';

import { Entity } from './Entity';

import OrganisationDashboard from './OrganisationDashboard';
import { Transaction } from './Transaction';
import { NotFound } from './components/notfound';

const Router = createFarceRouter({
  historyProtocol: new BrowserProtocol(),
  historyMiddlewares: [queryMiddleware],
  routeConfig: makeRouteConfig(
    <Route
    path="/:organisationId"
    Component={Organisation}
    query={graphql`
    query srcOrganisationQuery($organisationId: ID!) {
      me {
        firstName,
        surname
      }
      organisation(id:$organisationId) {
        id
        name
      }
    }
    `}
  >
      <Route Component={OrganisationDashboard} query={graphql`
    query srcOrganisationDashboardQuery($organisationId: ID!) {
      organisation(id:$organisationId) {
        name
      }
    }
    `} />
      <Route path="/settings" Component={OrganisationSettings} query={graphql`
    query srcOrganisationSettingsQuery($organisationId: ID!) {
      organisation(id:$organisationId) {
        name
        modules {
            name
            settings
        }
      }
    }
    `} />
      <Route path="/entities" Component={Entities} query={graphql`
      query srcEntitiesQuery($organisationId: ID!) {
      fetch__Organisation(id:$organisationId) {
        id
        ...Entities_organisation
      }
    }
        `} />
      <Route path="/entities/:entityId" Component={Entity} query={graphql`
    query srcEntityQuery($organisationId: ID!,$entityId: ID!){
      organisation(id:$organisationId) {
        entity(id:$entityId) {
          id
          name
          createdDate
          modifiedDate
          ...Metadata_object
          ...ActivityLog_object
          ...on PersonEntity {
            dateOfBirth
          }
        }
      }
    }
    `} />
      <Route path="/transactions" Component={Transactions} />
      <Route path="/transactions/:entityId" Component={Transaction} />
    </Route>
  ,
  ),
  renderError: ({ error }) => (<NotFound />),
  render: createRender({}),
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
  <Router resolver={new Resolver(environment) as FoundResolver} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
