import { useParams } from 'found';

import { Table, TableBody, TableCell, TableHead, TableRow } from './components/table';
import { Heading } from './components/heading';
import { Button } from './components/button';
import { graphql } from 'babel-plugin-relay/macro';
import { usePaginationFragment } from 'react-relay';
import { EntitiesPaginationQuery } from './__generated__/EntitiesPaginationQuery.graphql';
import { Link } from './components/link';
import { formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { Entities_organisation$key } from './__generated__/Entities_organisation.graphql';

function Entities({organisation, ...props} : {organisation:Entities_organisation$key, props:any}) {
    var params=useParams();
    console.log({props, type:'entities', params, organisation});
    const {data} = usePaginationFragment<EntitiesPaginationQuery, any>(
        graphql`
          fragment Entities_organisation on Organisation
          @refetchable(queryName: "EntitiesPaginationQuery")
          @argumentDefinitions(
            count: {type: "Int", defaultValue: 100},
            cursor: {type: "Int", defaultValue: -1},
          ) {
            id
            entities(first: $count, after: $cursor)
            @connection(key: "Entities_organisation_entities") {
              edges {
                node {
                  id
                  name
                  createdDate
                }
              }
            }
          }
        `,
        organisation,
      );
    return (
        <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <Heading>Entities</Heading>
      <div className="flex gap-4">
        <Button>Add New</Button>
      </div>
    </div>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>Entity</TableCell>
                        <TableCell>Created Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {(data.entities?.edges ?? []).map((edge: { node: { id: React.Key | null | undefined; name: string | null | undefined; createdDate: string | number | Date; }; }) => {
                        return (
                          <TableRow key={edge.node.id}>
                            <TableCell scope="row">
                            <Link href={`/${params.organisationId}/entities/${edge.node.id}`}>{edge.node.name}</Link>
                            </TableCell>
                            <TableCell>{edge.node.createdDate===null ? '' : formatRelative(new Date(edge.node.createdDate), new Date(),{locale:enGB})}</TableCell>
                          </TableRow>
                        );
                    })}
                    </TableBody>
                    </Table>
          </>);
  }

export default Entities;