import React, { useState } from 'react';
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks';
import { formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import JSONPretty from 'react-json-pretty';
import XMLViewer from 'react-xml-viewer';
import { ActivityLog_object$key } from './__generated__/ActivityLog_object.graphql';
import { Text } from './text';
import clsx from 'clsx';
import { GridContainer, GridItem } from './grid';
import Card from './card';
var JSONPrettyTheme = {
  main: 'line-height:1.3;color:#666;overflow-x:scroll;',
  error: 'line-height:1.3;color:#ed5f74;overflow-x:scroll;',
  key: 'color:#5469d4;',
  string: 'color:#09825d;',
  value: 'color:#ed5f74;',
  boolean: 'color:#067ab8;font-weight:600;',
};

export function ActivityLog(props:{object:ActivityLog_object$key}) {
  const [selected, setSelected] = useState("0");
    const data = useFragment(
        graphql`
          fragment ActivityLog_object on Object {
            activityLog {
            edges {
              node {
                type
                action
                timestamp
                input
              }
              cursor
            }
          }
          }
        `,
        props.object,
      );    
  if(data?.activityLog!==null && data?.activityLog!==undefined) {
    var selItem=(data?.activityLog.edges ?? []).filter((log)=>log.cursor===selected)[0];
    var preview: {} | null=null;
    if(selItem?.node.input?.substring(0,1)==='{') {
        preview=<JSONPretty data={selItem?.node.input} theme={JSONPrettyTheme}></JSONPretty>;
    }
    if(selItem?.node.input?.substring(0,1)==='<') {
        preview=<div style={{overflowX:'scroll'}}><XMLViewer xml={selItem?.node.input} /></div>;
    }
    var selElem=null;
    if(selItem!==null && selItem!==undefined) {
      selElem=<>
      <Text><code>{selItem?.node.type}.<strong>{selItem?.node.action}</strong></code></Text>
      <Text><small>{selItem===null ? '' : formatRelative(new Date(selItem?.node?.timestamp), new Date(),{locale:enGB})}</small></Text>
      {preview}
      </>;
    }
    return (<Card title="Activity Log">
    <GridContainer>
        <GridItem span={4}>
        <ul className="space-y-6">
        {(data?.activityLog.edges ?? []).map((log, i) => {
              return (
                <li key={log.cursor} className="relative flex gap-x-4" style={{padding:'8px',cursor:'pointer',backgroundColor:log.cursor===selected ? '#eee' : ''}} onClick={() => setSelected(log.cursor)}>
            <div
              className={clsx(
                i === (data?.activityLog.edges ?? []).length - 1 ? 'h-6' : '-bottom-6',
                'absolute left-0 top-0 flex w-6 justify-center',
              )}
            >
              <div className="w-px bg-gray-200" />
            </div>
                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                </div>
                <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                  <code>{log.node.type}.<strong>{log.node.action}</strong></code>
                </p>
                <time dateTime={log.node.timestamp} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                {formatRelative(new Date(log.node.timestamp), new Date(),{locale:enGB})}
                </time>
          </li>
              );
          })}
            </ul>
        </GridItem>
        <GridItem span={8}>
          {selElem}
        </GridItem>
    </GridContainer></Card>);
  }else{
    return null;
  }   
}