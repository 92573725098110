import { ActivityLog } from './components/ActivityLog';
import { Metadata } from './components/Metadata';
import { Avatar } from './components/avatar';
import { NotFound } from './components/notfound';
import Card from './components/card';
import { GridContainer, GridItem } from './components/grid';
import { srcEntityQuery$data } from './__generated__/srcEntityQuery.graphql';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from './components/description-list';
import { format } from 'date-fns';
import { useParams } from 'found';

export function Entity({organisation, ...props} : {organisation:srcEntityQuery$data["organisation"], props:any}) {
  var params=useParams();
  var entity=organisation?.entity;
  if(!entity) {
    return <NotFound />;
  }
  document.title=entity.name+ " | Beep";
  return (<GridContainer>
              <GridItem span={4}>
                  <Card>
                          <div style={{textAlign:'center'}}>
                              <Avatar className="mb-2 bg-zinc-900 text-white dark:bg-white dark:text-black" style={{width:200,height:200,marginLeft:'auto',marginRight:'auto'}} initials={entity.name?.match(/\b(\w)/g)?.join('')} />
                              <h2>{entity.name}</h2>
                          </div>
                          <DescriptionList>
                            <DescriptionTerm>ID</DescriptionTerm>
                            <DescriptionDetails><code>{entity.id}</code></DescriptionDetails>
                            {entity.dateOfBirth ? (<>
                              <DescriptionTerm>Date of Birth</DescriptionTerm>
                            <DescriptionDetails>{format(new Date(entity.dateOfBirth), 'dd/MM/yyyy')}</DescriptionDetails>
                            </>) : null}
                            
                          </DescriptionList>
                  </Card>
              </GridItem>
              <GridItem span={8}>
              <Metadata object={entity} organisationId={params.organisationId} />
              <ActivityLog object={entity} />
              </GridItem>
          </GridContainer>);
}