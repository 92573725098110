/**
 * @generated SignedSource<<7f67a8cbbd273373c057fb06523be255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type srcOrganisationQuery$variables = {
  organisationId: string;
};
export type srcOrganisationQuery$data = {
  readonly me: {
    readonly firstName: string | null | undefined;
    readonly surname: string | null | undefined;
  } | null | undefined;
  readonly organisation: {
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
};
export type srcOrganisationQuery = {
  response: srcOrganisationQuery$data;
  variables: srcOrganisationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surname",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "organisationId"
    }
  ],
  "concreteType": "Organisation",
  "kind": "LinkedField",
  "name": "organisation",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "srcOrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "srcOrganisationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "5d3bb333b43a9e6c4ac289747b48e8c0",
    "id": null,
    "metadata": {},
    "name": "srcOrganisationQuery",
    "operationKind": "query",
    "text": "query srcOrganisationQuery(\n  $organisationId: ID!\n) {\n  me {\n    firstName\n    surname\n    id\n  }\n  organisation(id: $organisationId) {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6362ee332cedde69f866bf9b66f8308";

export default node;
