export default function Card({title, buttons, children}: { title?:string, buttons?:React.ReactNode, children: React.ReactNode }) {
  if(title) {
    return (<div className="overflow-hidden rounded-lg bg-white dark:bg-black dark:text-white shadow-sm mb-3">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold text-gray-900">{title}</h3>
        </div>
        <div className="ml-4 mt-2 shrink-0">
          {buttons}
        </div>
      </div>
    </div>
        <div className="px-4 py-5 sm:p-6">{children}</div>
      </div>);
  }
    return (
      <div className="overflow-hidden rounded-lg bg-white dark:bg-black dark:text-white shadow-sm mb-3">
        <div className="px-4 py-5 sm:p-6">{children}</div>
      </div>
    )
  }