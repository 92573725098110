import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Dialog,
  DialogPanel,
  DialogBackdrop,
} from '@headlessui/react'

import { Dispatch, SetStateAction, useState } from 'react'

import { mdiAlertCircle, mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';

const people = [
  { id: 1, name: 'Leslie Alexander', url: '#' },
  // More people...
]

export default function Search({open, setOpen}: {open: boolean, setOpen: Dispatch<SetStateAction<boolean>>}) {
  const [query, setQuery] = useState('')

  const filteredPeople =
    query === ''
      ? []
      : people.filter((person) => {
          return person.name.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Dialog
      className="relative z-10"
      open={open}
      onClose={() => {
        setOpen(false)
        setQuery('')
      }}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity data-closed:opacity-0 data-enter:duration-300 data-leave:duration-200 data-enter:ease-out data-leave:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
        <DialogPanel
          transition
          className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all data-closed:scale-95 data-closed:opacity-0 data-enter:duration-300 data-leave:duration-200 data-enter:ease-out data-leave:ease-in"
        >
          <Combobox
            onChange={(person) => {
              if (person) {
                //window.location = person.url
              }
            }}
          >
            <div className="relative">
            <Icon path={mdiMagnify} size="20px" className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
              <ComboboxInput
                autoFocus
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..." style={{outline:0}}
                onChange={(event) => setQuery(event.target.value)}
                onBlur={() => setQuery('')}
              />
            </div>

            {filteredPeople.length > 0 && (
              <ComboboxOptions static className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                {filteredPeople.map((person) => (
                  <ComboboxOption
                    key={person.id}
                    value={person}
                    className="cursor-default select-none px-4 py-2 data-focus:bg-indigo-600 data-focus:text-white"
                  >
                    {person.name}
                  </ComboboxOption>
                ))}
              </ComboboxOptions>
            )}

            {query !== '' && filteredPeople.length === 0 && (
              <div className="px-6 py-14 text-center text-sm sm:px-14">
              <Icon path={mdiAlertCircle} size="24px" className="mx-auto h-6 w-6 text-gray-400" />
              <p className="mt-4 font-semibold text-gray-900">No results found</p>
              <p className="mt-2 text-gray-500">No results found for this search term. Please try again.</p>
            </div>
            )}
          </Combobox>
        </DialogPanel>
      </div>
    </Dialog>
  )
}