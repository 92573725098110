/**
 * @generated SignedSource<<a180d8df36d6e836469da5faeb5fb3ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MetadataInput = {
  name: string;
  value?: string | null | undefined;
};
export type Metadata_Edit_Mutation$variables = {
  metadata?: ReadonlyArray<MetadataInput | null | undefined> | null | undefined;
  objectId: string;
  organisationId: string;
};
export type Metadata_Edit_Mutation$data = {
  readonly editMetadata: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityLog_object" | "Metadata_object">;
  } | null | undefined;
};
export type Metadata_Edit_Mutation = {
  response: Metadata_Edit_Mutation$data;
  variables: Metadata_Edit_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "metadata"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "metadata",
    "variableName": "metadata"
  },
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "objectId"
  },
  {
    "kind": "Variable",
    "name": "organisation",
    "variableName": "organisationId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Metadata_Edit_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editMetadata",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Metadata_object"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActivityLog_object"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Metadata_Edit_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "editMetadata",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isObject"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Metadata",
            "kind": "LinkedField",
            "name": "metadata",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ActivityLogConnection",
            "kind": "LinkedField",
            "name": "activityLog",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivityLogEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLog",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "action",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "input",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57b70370c5ab3818c7102c258a493e47",
    "id": null,
    "metadata": {},
    "name": "Metadata_Edit_Mutation",
    "operationKind": "mutation",
    "text": "mutation Metadata_Edit_Mutation(\n  $organisationId: ID!\n  $objectId: ID!\n  $metadata: [MetadataInput]\n) {\n  editMetadata(organisation: $organisationId, object: $objectId, metadata: $metadata) {\n    __typename\n    ...Metadata_object\n    ...ActivityLog_object\n    id\n  }\n}\n\nfragment ActivityLog_object on Object {\n  __isObject: __typename\n  activityLog {\n    edges {\n      node {\n        type\n        action\n        timestamp\n        input\n      }\n      cursor\n    }\n  }\n}\n\nfragment Metadata_object on Object {\n  __isObject: __typename\n  id\n  metadata {\n    name\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "126dc24cc0a0ce4c49513220ea3d18e2";

export default node;
