import { Route } from 'found';
import { GridContainer, GridItem } from './components/grid';
import Card from './components/card';
import { Text } from './components/text';

//const controllable_modules=[{type:'wiki',name:'Wiki'},{type:'blog',name:'Blog'},{type:'crm',name:'CRM'},{type:'shop',name:'Shop'}];
//const fixed_modules=[{type:'dashboard',name:'Dashboard'}];

function OrganisationSettings(props : any) {
    /*const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
    var modstate:any={};
    controllable_modules.forEach(e => {
      modstate[e.type]=props.modules?.modules?.filter((m: { name: string; })=>m.name===e.type).length===1;
    });
    const [state, setState] = React.useState(modstate);
  
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      commitMutation(
        environment,
        {
          mutation:graphql`
          mutation OrganisationSettings_SaveModule_Mutation(
            $organisationId:ID!,
            $moduleName:String,
            $active:Boolean,
            $settings:[String]
          ) {
            editModuleSettings(organisation:$organisationId, moduleName:$moduleName, active:$active, settings: $settings) {
              id,
              modules {
                name
                settings
              }
            }
          }
        `,
          variables:{organisationId,moduleName:event.target.name, active:event.target.checked, settings:[]},
        },
      );
    };*/

    return (
      <GridContainer>
        <GridItem span={3}>
        <Card>
          <div></div>
          </Card>
          </GridItem>
        <GridItem span={9}>
              <Route path="users" render={(args) => <><h2>Users</h2>
                <Card>
              <Text>
                Name
              </Text>
              <Text>
                Email Address
              </Text>
                    {props?.users?.users?.edges?.map((edge: any) =>(<>
                        <Text>{edge.node.user.firstName} {edge.node.user.surname}</Text>
                            <Text><code>{edge.node.user.emailAddress}</code></Text>
                            </>
                        ))}
      </Card></>} />
              <Route path="modules" render={(args) => <div>
                <h2>Modules</h2>
                {/*controllable_modules.map(e => 
      <Accordion key={"mod-"+e.type} expanded={expanded === e.type} onChange={handleChange(e.type)}>
      <AccordionSummary
        expandIcon={<Icon path={mdiChevronDown} size={1} />}
        aria-controls={e.type+'-content'}
        id={e.type+'-header'}
      >
        <Typography className={classes.heading}>{e.name}</Typography>
        <Typography className={classes.secondaryHeading}>{state[e.type] ? "Enabled" : "Disabled"}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        <FormControlLabel
control={
<Switch
  checked={state[e.type]}
  onChange={handleSwitchChange}
  name={e.type}
  color="primary"
/>
}
label="Enabled"
/>
        </Typography>
      </AccordionDetails>
    </Accordion>
    )*/}
    {/*fixed_modules.filter(a=> props.modules?.modules?.filter((m: { name: string; })=>m.name===a.type).length===1).map(e => 
      <Accordion expanded={expanded === e.type} onChange={handleChange(e.type)}>
      <AccordionSummary
        expandIcon={<Icon path={mdiChevronDown} size={1} />}
        aria-controls={e.type+'-content'}
        id={e.type+'-header'}
      >
        <Typography className={classes.heading}>{e.name}</Typography>
        <Typography className={classes.secondaryHeading}></Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
        
        </Typography>
      </AccordionDetails>
    </Accordion>
    )*/}
                
              </div>} />
              <Route render={(args) => <div>
                <h2>Organisation Settings</h2>
                <code>{props.organisationId}</code>
              </div>} />
        </GridItem>
      </GridContainer>);
  }

  export default OrganisationSettings;