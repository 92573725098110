import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks';
import { TransactionQuery } from './__generated__/TransactionQuery.graphql';
import { formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';
import Card from './components/card';
import { GridContainer, GridItem } from './components/grid';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from './components/description-list';
import { NotFound } from './components/notfound';
import { useParams } from 'found';

export function Transaction(props : any) {
  var params=useParams();
  const data = useLazyLoadQuery<TransactionQuery>(
    graphql`
    query TransactionQuery($organisationId: ID!,$transactionId: ID!){
      organisation(id:$organisationId) {
        transaction(id:$transactionId) {
          id
          transactionType
          status
          createdDate
          transactionDate
          modifiedDate
        }
      }
    }
    `,
    {organisationId:params.organisationId,transactionId:params.transactionId},
  );
  /*
  ...on TransitJourneyTransaction {
            startLocation {
              name
              lat
              lon
            }
            endLocation {
              name
              lat
              lon
            }
          }
            ...Metadata_object
          ...ActivityLog_object
          */
  if(data?.organisation?.transaction!==null && data?.organisation?.transaction!==undefined) {
    var transaction=data?.organisation?.transaction;
    document.title=transaction.transactionType+ " | Beep";
    return (<div><Card>
            <GridContainer>
                <GridItem span={6}>
                    <div style={{fontSize:'12px'}}>{transaction.transactionType.replace("_",' ')}</div>
                </GridItem>
                <GridItem span={6}>
                    <code style={{fontSize:'12px'}}>{transaction.id}</code>
                </GridItem>
            </GridContainer>
            <DescriptionList>
              <DescriptionTerm>Date</DescriptionTerm>
              <DescriptionDetails>{transaction.transactionDate===null ? '' : formatRelative(new Date(transaction.transactionDate), new Date(),{locale:enGB})}</DescriptionDetails>
              <DescriptionTerm>Status</DescriptionTerm>
              <DescriptionDetails>{transaction.status}</DescriptionDetails>
              <DescriptionTerm>Created Date</DescriptionTerm>
              <DescriptionDetails>{transaction.createdDate}</DescriptionDetails>
              <DescriptionTerm>Modified Date</DescriptionTerm>
              <DescriptionDetails>{transaction.modifiedDate}</DescriptionDetails>
            </DescriptionList>
      </Card></div>);
  }else{
    return (<NotFound />);
  }   
}