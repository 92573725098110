/**
 * @generated SignedSource<<d22e2bb5aeb71ffc2a4c057d54a55dcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type srcOrganisationSettingsQuery$variables = {
  organisationId: string;
};
export type srcOrganisationSettingsQuery$data = {
  readonly organisation: {
    readonly modules: ReadonlyArray<{
      readonly name: string;
      readonly settings: ReadonlyArray<string | null | undefined> | null | undefined;
    }>;
    readonly name: string | null | undefined;
  } | null | undefined;
};
export type srcOrganisationSettingsQuery = {
  response: srcOrganisationSettingsQuery$data;
  variables: srcOrganisationSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organisationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Module",
  "kind": "LinkedField",
  "name": "modules",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settings",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "srcOrganisationSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "srcOrganisationSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e7e7479889d47c5d7bdd50c5ee4127a",
    "id": null,
    "metadata": {},
    "name": "srcOrganisationSettingsQuery",
    "operationKind": "query",
    "text": "query srcOrganisationSettingsQuery(\n  $organisationId: ID!\n) {\n  organisation(id: $organisationId) {\n    name\n    modules {\n      name\n      settings\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "20b3cedfccd2f22e2d2a3c814ce490cc";

export default node;
