import {
    Environment,
    Network,
    RecordSource,
    Store,
  } from 'relay-runtime';
  
  function fetchQuery(
    operation:any,
    variables:any,
  ) {
    let debug=true;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    }else{
      debug=false;
    }
    return fetch(debug ? 'http://localhost:8080/' : 'https://api.europe-west2.beep.dev/', {
      method: 'POST',
      credentials:'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    }).then(response => {
      return response.json();
    });
  }
  
  const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
  });
  
  export default environment;