import clsx from "clsx";

export function GridContainer({className, children, cols = 12,...props}: { className?: string, cols?: number, children: React.ReactNode }) {
    var colsType: { [key: number]: string } = {
        12: "grid-cols-12",
    };
    return (
        <div className={clsx(className, "grid "+colsType[cols]+" items-start gap-x-4 gap-y-4")} {...props}>{children}</div>
    )
}

export function GridItem({className, span = 1, children, ...props}: { className?: string, span?: number, children: React.ReactNode }) {
    var colsType: { [key: number]: string } = {
        1: "col-span-1",
        2: "col-span-2",
        3: "col-span-3",
        4: "col-span-4",
        5: "col-span-5",
        6: "col-span-6",
        7: "col-span-7",
        8: "col-span-8",
        9: "col-span-9",
        10: "col-span-10",
        11: "col-span-11",
        12: "col-span-12",
    };
    return (
        <section className={clsx(className, colsType[span])} {...props}>{children}</section>
    )
}

