/* eslint-disable jsx-a11y/anchor-has-content */
/**
 * TODO: Update this component to use your client-side framework's link
 * component. We've provided examples of how to do this for Next.js, Remix, and
 * Inertia.js in the Catalyst documentation:
 *
 * https://catalyst.tailwindui.com/docs#client-side-router-integration
 */

import * as Headless from '@headlessui/react'
import { useRouter } from 'found';
import React, { forwardRef, useCallback } from 'react'

export const Link = forwardRef(function Link(
  props: { href: string } & React.ComponentPropsWithoutRef<'a'>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { router } = useRouter();

  const onClick = useCallback((e: { preventDefault: () => void; }) => {
    e.preventDefault();
    router.replace(props.href);
  }, [props.href, router]);
  return (
    <Headless.DataInteractive>
      <a {...props} onClick={onClick}  ref={ref} />
    </Headless.DataInteractive>
  )
})
