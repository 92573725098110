/**
 * @generated SignedSource<<26ec32b63168ebe2db263cdb610b9344>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type srcEntityQuery$variables = {
  entityId: string;
  organisationId: string;
};
export type srcEntityQuery$data = {
  readonly organisation: {
    readonly entity: {
      readonly createdDate: any;
      readonly dateOfBirth?: any | null | undefined;
      readonly id: string;
      readonly modifiedDate: any;
      readonly name: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"ActivityLog_object" | "Metadata_object">;
    } | null | undefined;
  } | null | undefined;
};
export type srcEntityQuery = {
  response: srcEntityQuery$data;
  variables: srcEntityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organisationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organisationId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "entityId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modifiedDate",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateOfBirth",
      "storageKey": null
    }
  ],
  "type": "PersonEntity",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "srcEntityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Metadata_object"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ActivityLog_object"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "srcEntityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organisation",
        "kind": "LinkedField",
        "name": "organisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "entity",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Metadata",
                    "kind": "LinkedField",
                    "name": "metadata",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActivityLogConnection",
                    "kind": "LinkedField",
                    "name": "activityLog",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivityLogEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ActivityLog",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "timestamp",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "input",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Object",
                "abstractKey": "__isObject"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61dd8cacd1eb417c1c554c81dca86005",
    "id": null,
    "metadata": {},
    "name": "srcEntityQuery",
    "operationKind": "query",
    "text": "query srcEntityQuery(\n  $organisationId: ID!\n  $entityId: ID!\n) {\n  organisation(id: $organisationId) {\n    entity(id: $entityId) {\n      __typename\n      id\n      name\n      createdDate\n      modifiedDate\n      ...Metadata_object\n      ...ActivityLog_object\n      ... on PersonEntity {\n        dateOfBirth\n      }\n    }\n    id\n  }\n}\n\nfragment ActivityLog_object on Object {\n  __isObject: __typename\n  activityLog {\n    edges {\n      node {\n        type\n        action\n        timestamp\n        input\n      }\n      cursor\n    }\n  }\n}\n\nfragment Metadata_object on Object {\n  __isObject: __typename\n  id\n  metadata {\n    name\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "55e58c00eba49db69adfdd288bf2f0d2";

export default node;
